import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, ArrowLeft, Loader, Search, ChevronDown, X, AlertCircle, FolderPlus, Plus } from 'lucide-react';
import FilterSelector from './FilterSelector';
import './ResultsPage.css';

const DEFAULT_FILTER = { id: 'uncharted', streamThreshold: 0 };
const ITEMS_PER_PAGE = 25;
const BACKEND = "https://emergesound.ai/api";

const LanguageToggle = ({ enabled, onChange }) => {
  return (
    <div className="language-toggle flex items-center gap-2">
      <button
        onClick={() => onChange(!enabled)}
        className="relative inline-flex items-center h-5 rounded-full w-9"
        style={{
          backgroundColor: enabled
            ? 'rgba(255, 240, 220, 0.3)'
            : 'rgba(42, 30, 20, 0.9)'
        }}
      >
        <span
          className={`${
            enabled ? 'translate-x-4' : 'translate-x-1'
          } inline-block w-3 h-3 transform rounded-full transition-transform duration-200 ease-in-out`}
          style={{
            backgroundColor: 'rgb(255, 240, 220)'
          }}
        />
      </button>
      <span
        className="text-sm font-mono"
        style={{ color: 'rgb(255, 240, 220)' }}
      >
        English Only
      </span>
    </div>
  );
};

const ReferenceSong = ({ song, currentlyPlaying, isPlaying, onPlay }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className={`reference-song ${isMobile ? 'mobile' : ''}`}>
      <div className="reference-artwork">
        <img src={song.artwork_url} alt={song.title} className="reference-image" />
        <button
          className={`play-button ${currentlyPlaying?.id === song.id && isPlaying ? 'playing' : ''}`}
          onClick={() => onPlay(song)}
          disabled={!song.preview_url}
        >
          {currentlyPlaying?.id === song.id && isPlaying ? (
            <Pause className="play-icon" />
          ) : (
            <Play className="play-icon" />
          )}
        </button>
      </div>
      <div className="reference-info">
        <div className="reference-song-title ${isMobile ? 'mobile' : ''}">{song.title}</div>
        <div className="reference-artist ${isMobile ? 'mobile' : ''}">{song.artist}</div>
        <div className="reference-album ${isMobile ? 'mobile' : ''}">{song.album}</div>
      </div>
    </div>
  );
};

const SongItem = ({ song, currentlyPlaying, isPlaying, onPlay, onShowSimilar, onAddToCrate }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className={`result-item ${currentlyPlaying?.id === song.id ? 'playing' : ''} ${isMobile ? 'mobile' : ''}`}>
      <div className="result-artwork">
        <img src={song.artwork_url} alt={song.title} className="result-image" />
        <button
          className={`play-button ${currentlyPlaying?.id === song.id && isPlaying ? 'playing' : ''}`}
          onClick={() => onPlay(song)}
          disabled={!song.preview_url}
        >
          {currentlyPlaying?.id === song.id && isPlaying ? (
            <Pause className="play-icon" />
          ) : (
            <Play className="play-icon" />
          )}
        </button>
      </div>
      
      <div className="result-info">
        <div className="result-title">{song.title}</div>
        <div className="result-artist">{song.artist}</div>
        <div className="result-album">{song.album} • {song.year}</div>
      </div>

      <div className="result-actions">
        <button
          className="action-button"
          onClick={() => onAddToCrate(song)}
        >
          <FolderPlus className="action-icon" />
          {!isMobile && <span>Add to Crate</span>}
        </button>
        <button
          className="action-button"
          onClick={() => onShowSimilar(song)}
        >
          <Search className="action-icon" />
          {!isMobile && <span>Show Similar</span>}
        </button>
      </div>
    </div>
  );
};

const FloatingPlayer = ({ currentSong, isPlaying, progress, duration, onSeek, onPlayPause }) => {
  const [isMobile, setIsMobile] = useState(false);
  const progressBarRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSeek = (e) => {
    if (!progressBarRef.current) return;
    const rect = progressBarRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    onSeek(position * duration);
  };

  const formatTime = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  if (!currentSong) return null;

  return (
    <div className={`floating-player ${isMobile ? 'mobile' : ''}`}>
      <div className="player-container">
        <div className="player-main-controls">
          <div className="player-controls">
            <button className="player-play-button" onClick={onPlayPause}>
              {isPlaying ? (
                <Pause className="w-5 h-5" />
              ) : (
                <Play className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="player-artwork">
            <img src={currentSong.artwork_url} alt={currentSong.title} />
          </div>

          <div className="player-info">
            <div className="player-title">{currentSong.title}</div>
            <div className="player-artist">{currentSong.artist}</div>
          </div>
        </div>

        <div className="player-progress">
          <div
            ref={progressBarRef}
            className="progress-bar"
            onClick={handleSeek}
          >
            <div
              className="progress-bar-fill"
              style={{ width: `${(progress / duration) * 100}%` }}
            />
          </div>
          <div className="player-time">
            {formatTime(progress)} / {formatTime(duration)}
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultsPage = ({ initialData, onBack, session, consumeCredit, setIsVideoPlaying, onCreateNewCrate, setShowAuthModal }) => {
  const [songs, setSongs] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(DEFAULT_FILTER);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(ITEMS_PER_PAGE);
  const [currentReference, setCurrentReference] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isEnglishOnly, setIsEnglishOnly] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [showAddToCrateModal, setShowAddToCrateModal] = useState(false);
  const [crates, setCrates] = useState([]);
  const [cratesLoading, setCratesLoading] = useState(false);
  const [cratesError, setcratesError] = useState('');
    
  const audioRef = useRef(new Audio());
  const streamRef = useRef(null);
  const searchTimeoutRef = useRef(null);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

    useEffect(() => {
        if (initialData?.uploadFile) {
            fetchUploadResults(initialData.uploadFile);
        } else if (initialData?.song) {
            setCurrentReference(initialData.song);
            fetchSimilarSongs(initialData.song);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
            }
            if (streamRef.current) {
                streamRef.current.abort();
            }
        };
    }, [initialData]);

  useEffect(() => {
    if (songs.length > 0) {
      const filtered = filterSongs(songs, selectedFilter);
      setFilteredSongs(filtered);
    }
  }, [songs, selectedFilter, isEnglishOnly]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setProgress(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handlePlay = async (song) => {
    const audio = audioRef.current;

    if (currentlyPlaying?.id === song.id) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        await audio.play();
        setIsPlaying(true);
      }
      return;
    }

    try {
      audio.src = song.preview_url;
      setCurrentlyPlaying(song);
      await audio.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Playback failed:', error);
      setCurrentlyPlaying(null);
      setIsPlaying(false);
    }
  };

  const handleSeek = (time) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = time;
      setProgress(time);
    }
  };

    const filterSongs = (songs, filter) => {
      if (!Array.isArray(songs) || !filter?.id) return [];

        console.log(isEnglishOnly);
        
      const thresholds = {
        'uncharted': 0,
        'upcoming': 500000,
        'popular': 5000000
      };
      
      const threshold = thresholds[filter.id] || thresholds.popular;
      
      // Set of English-speaking country codes
      const englishCountries = new Set([
        'US', // United States
        'GB', // United Kingdom
        'CA', // Canada
        'AU', // Australia
        'NZ', // New Zealand
        'IE', // Ireland
        'QZ', // Unregistered (often UK)
        'ZZ'  // Unknown (keep these as they're often English)
      ]);
      
      const seen = new Set();
      let filtered = songs.filter(song => {
        const songKey = `${song.title.toLowerCase()}-${song.artist.toLowerCase()}`;
        
        if (currentReference &&
            song.title.toLowerCase() === currentReference.title.toLowerCase() &&
            song.artist.toLowerCase() === currentReference.artist.toLowerCase()) {
          return false;
        }
        
        if (seen.has(songKey)) return false;
        
        // Apply English language filter if enabled
        if (isEnglishOnly && song.isrc && englishCountries.has(song.isrc.slice(0, 2))) {
          return false;
        }
        
        seen.add(songKey);
        return true;
      });
      
      if (filter.id !== 'uncharted') {
        filtered = filtered.filter(song => song.stream_count >= threshold);
      }
      
      return filtered.sort((a, b) => (b.score || 0) - (a.score || 0));
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
          setIsSearchFocused(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    
    const handleSearchChange = async (e) => {
      const value = e.target.value;
      setSearchTerm(value);

      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      if (value.trim()) {
        searchTimeoutRef.current = setTimeout(async () => {
          try {
            const response = await fetch(`${BACKEND}/search`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                query: value,
                limit: 25
              })
            });

            if (!response.ok) {
              throw new Error(await response.text());
            }

            const data = await response.json();
            setSearchResults(data);
          } catch (error) {
            console.error('Search failed:', error);
          }
        }, 50);
      } else {
        setSearchResults([]);
      }
    };
    


    // Add a function to fetch crates
      const fetchCrates = async () => {
        setCratesLoading(true);
        setcratesError('');
        
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${BACKEND}/crates/`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) throw new Error('Failed to fetch crates');
          const data = await response.json();
          setCrates(data);
        } catch (err) {
          setcratesError(err.message);
        } finally {
          setCratesLoading(false);
        }
      };

      useEffect(() => {
          if (crates.length === 0 && !cratesLoading) {
            fetchCrates();
           }
      }, []);

      // Modify the existing AddToCrateModal component to accept crates as a prop
      const AddToCrateModal = ({ isOpen, onClose, song }) => {
        const [adding, setAdding] = useState(false);
        const [error, setError] = useState('');

        const handleAddToCrate = async (crateId) => {
          setAdding(true);
          setError('');

          try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BACKEND}/crates/${crateId}/tracks`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(song)
            });

            if (!response.ok) throw new Error('Failed to add track to crate');
            onClose();
          } catch (err) {
            setError(err.message);
          } finally {
            setAdding(false);
          }
        };

        if (!isOpen) return null;
          
        return (
          <div className="auth-modal-overlay" onClick={onClose}>
            <div className="auth-modal" onClick={e => e.stopPropagation()}>
              <button className="auth-close" onClick={onClose}>
                <X size={20} />
              </button>

              <div className="auth-modal-header">
                <h2 className="auth-modal-title">Add to Crate</h2>
                <p className="auth-modal-subtitle">
                  Select a crate to add "{song.title}"
                </p>
              </div>

              {error && (
                <div className="auth-error-message">
                  <AlertCircle size={16} />
                  <span>{error}</span>
                </div>
              )}

              {cratesLoading ? (
                <div className="loading-container">
                  <Loader className="loading-icon animate-spin" />
                </div>
              ) : (
                <div className="crate-list">
                  {crates.length === 0 ? (
                    <div className="no-crates-message">
                      <p>You don't have any crates yet.</p>
                      <button
                        className="create-crate-button"
                        onClick={() => {
                          onClose();
                          onCreateNewCrate();
                        }}
                      >
                        <FolderPlus size={20} />
                        <span>Create New Crate</span>
                      </button>
                    </div>
                  ) : (
                    crates.map(crate => (
                      <button
                        key={crate.id}
                        className="crate-select-item"
                        onClick={() => handleAddToCrate(crate.id)}
                        disabled={adding}
                      >
                        <div className="crate-select-info">
                          <span className="crate-select-name">{crate.name}</span>
                          <span className="crate-select-count">
                            {crate.track_count} tracks
                          </span>
                        </div>
                        <Plus size={16} />
                      </button>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        );
      };

      // Modify the "Add to Crate" click handler to fetch crates if needed
      const handleAddToCrateClick = async (song) => {
        const token = localStorage.getItem('token');
        if (!token) {
          setShowAuthModal(true);
          return;
        }
        
        // Only fetch crates if we haven't already
        if (crates.length === 0 && !cratesLoading) {
          await fetchCrates();
        }
        
        setSelectedSong(song);
        setShowAddToCrateModal(true);
      };

  const handleTrackSelect = async (song) => {
    const hasCredit = await consumeCredit();
    if (!hasCredit) return;

    setSearchTerm('');
    setSearchResults([]);
    setIsSearchFocused(false);
    setCurrentReference(song);
    setSongs([]);
    setFilteredSongs([]);
    await fetchSimilarSongs(song);
  };

    useEffect(() => {
        if (songs.length >= 5) {
          setIsVideoPlaying(false);
          setShowContent(true);
        }
      }, [songs.length, setIsVideoPlaying]);
    
  const fetchSimilarSongs = async (song) => {
    if (!song) return;

    const controller = new AbortController();
    streamRef.current = controller;

    try {
      const response = await fetch(`${BACKEND}/similar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/x-ndjson',
        },
        body: JSON.stringify({
          song_id: song.id,
          isrc: song.isrc,
          start_year: initialData.startYear,
          end_year: initialData.endYear,
        }),
        signal: controller.signal
      });

      if (!response.ok) throw new Error(`Failed to fetch songs: ${response.status}`);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        const newSongs = lines
          .filter(line => line.trim())
          .map(line => JSON.parse(line));

          if (newSongs.length > 0) {
            setSongs(prev => {
              const updatedSongs = [...prev, ...newSongs];
              return updatedSongs;
            });
          }
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Failed to fetch similar songs:', error);
      }
    }
  };
    
    const fetchUploadResults = async (fileData) => {
        if (!fileData) return;

        const controller = new AbortController();
        streamRef.current = controller;

        try {
            // Create FormData
            const formData = new FormData();
            formData.append('file', fileData.file);
            formData.append('start_time', fileData.startTime.toString());
            formData.append('end_time', fileData.endTime.toString());

            // Make the upload request
            const response = await fetch(`${BACKEND}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/x-ndjson'
                },
                signal: controller.signal
            });

            if (!response.ok) throw new Error('Upload failed');

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');
                buffer = lines.pop() || '';

                const newSongs = lines
                    .filter(line => line.trim())
                    .map(line => JSON.parse(line));

                if (newSongs.length > 0) {
                    setSongs(prev => [...prev, ...newSongs]);
                }
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Failed to process upload:', error);
            }
        }
    };

  const handleShowSimilar = async (song) => {
    const hasCredit = await consumeCredit();
    if (!hasCredit) return;

    setCurrentReference(song);
    setSongs([]);
    setFilteredSongs([]);
    await fetchSimilarSongs(song);
  };

  const handleShowMore = () => {
    setDisplayLimit(prev => Math.min(prev + ITEMS_PER_PAGE, filteredSongs.length));
  };

  const handleBack = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.src = '';
    }
    if (streamRef.current) {
      streamRef.current.abort();
    }
    setCurrentReference(null);
    setSongs([]);
    setFilteredSongs([]);
    onBack();
  };
        
    const handleCreateCrate = async (name) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${BACKEND}/crates/`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name })
        });

        if (!response.ok) throw new Error('Failed to create crate');
        
        // After successful creation, navigate to crates page
        onCreateNewCrate();
      } catch (err) {
        console.error('Error creating crate:', err);
        // You might want to show an error message to the user here
      }
    };
    
  if (!showContent) {
    return null; // This will show nothing except the video (which is rendered in App.js)
  }

  return (
    <div className={`results-page ${isMobile ? 'mobile' : ''}`}>
      <div className={`back-button ${isMobile ? 'mobile' : ''}`} onClick={handleBack}>
        <ArrowLeft className="back-icon" />
        {!isMobile && "Back"}
      </div>

          <div ref={searchContainerRef} className={`results-search ${isMobile ? 'mobile' : ''}`}>
            <div className="search-wrapper">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchFocused(true)}
                placeholder="Search for a song..."
                className="search-input"
              />
            </div>
            {searchResults.length > 0 && isSearchFocused && (
              <div className="search-results">
                {searchResults.map((song) => (
                  <div
                    key={song.id}
                    className="search-result-item"
                    onClick={() => handleTrackSelect(song)}
                  >
                    <img
                      src={song.artwork_url}
                      alt={song.title}
                      className="search-result-artwork"
                    />
                    <div className="search-result-info">
                      <div className="search-result-title">{song.title}</div>
                      <div className="search-result-artist">{song.artist}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

    {currentReference && (
      <div className={`reference-container ${isMobile ? 'mobile' : ''}`}>
        <div className={`reference-header ${isMobile ? 'mobile' : ''}`}>
          <div className="reference-title ${isMobile ? 'mobile' : ''}">Showing songs similar to...</div>
          <ReferenceSong
            song={currentReference}
            currentlyPlaying={currentlyPlaying}
            isPlaying={isPlaying}
            onPlay={handlePlay}
          />
        </div>
      </div>
    )}

    <div className={`results-section ${isMobile ? 'mobile' : ''}`}>
          <div className={`filter-container ${isMobile ? 'mobile' : ''}`}>
            <div className="filter-content ${isMobile ? 'mobile' : ''}">
              <FilterSelector onChange={setSelectedFilter} />
            </div>
              <div className="language-toggle-content">
                {!isMobile &&
                  <LanguageToggle
                    enabled={isEnglishOnly}
                    onChange={setIsEnglishOnly}
                  />
                }
              </div>
          </div>
          {/*<div className={`filter-container ${isMobile ? 'mobile' : ''}`}>
        <FilterSelector onChange={setSelectedFilter} />
          </div>*/}

      <div className="results-header">
        <div>Similar Songs ({filteredSongs.length} tracks)</div>
      </div>

      <div className={`results-list ${isMobile ? 'mobile' : ''}`}>
          {filteredSongs.slice(0, displayLimit).map(song => (
            <SongItem
              key={song.id}
              song={song}
              currentlyPlaying={currentlyPlaying}
              isPlaying={isPlaying}
              onPlay={handlePlay}
              onShowSimilar={handleShowSimilar}
              onAddToCrate={(song) => {
                const token = localStorage.getItem('token');
                if (!token) {
                  setShowAuthModal(true);
                  return;
                }
                setSelectedSong(song);
                setShowAddToCrateModal(true);
              }}
            />
          ))}
      </div>

      {displayLimit < filteredSongs.length && (
        <button className={`show-more-button ${isMobile ? 'mobile' : ''}`} onClick={handleShowMore}>
          <ChevronDown className="show-more-icon" />
          Show More ({filteredSongs.length - displayLimit} remaining)
        </button>
      )}
    </div>

    {currentlyPlaying && (
      <FloatingPlayer
        currentSong={currentlyPlaying}
        isPlaying={isPlaying}
        progress={progress}
        duration={duration}
        onSeek={handleSeek}
        onPlayPause={() => handlePlay(currentlyPlaying)}
      />
    )}
          
  {showAddToCrateModal && selectedSong && (
    <AddToCrateModal
      isOpen={showAddToCrateModal}
      onClose={() => {
        setShowAddToCrateModal(false);
        setSelectedSong(null);
      }}
      song={selectedSong}
    />
  )}
  </div>
);
};

export default ResultsPage;
