import React, { useState, useEffect, useRef } from 'react';
import { Plus, FolderPlus, Play, Pause, Loader, ArrowLeft, ChevronDown, AlertTriangle } from 'lucide-react';
import './CratesPage.css';

const BACKEND = "https://emergesound.ai/api";
const ITEMS_PER_PAGE = 25;

const FloatingPlayer = ({ currentSong, isPlaying, progress, duration, onSeek, onPlayPause }) => {
  const [isMobile, setIsMobile] = useState(false);
  const progressBarRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSeek = (e) => {
    if (!progressBarRef.current) return;
    const rect = progressBarRef.current.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    onSeek(position * duration);
  };

  const formatTime = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  if (!currentSong) return null;

  return (
    <div className={`floating-player ${isMobile ? 'mobile' : ''}`}>
      <div className="player-container">
        <div className="player-main-controls">
          <div className="player-controls">
            <button className="player-play-button" onClick={onPlayPause}>
              {isPlaying ? (
                <Pause className="w-5 h-5" />
              ) : (
                <Play className="w-5 h-5" />
              )}
            </button>
          </div>

          <div className="player-artwork">
            <img src={currentSong.artwork_url} alt={currentSong.title} />
          </div>

          <div className="player-info">
            <div className="player-title">{currentSong.title}</div>
            <div className="player-artist">{currentSong.artist}</div>
          </div>
        </div>

        <div className="player-progress">
          <div
            ref={progressBarRef}
            className="progress-bar"
            onClick={handleSeek}
          >
            <div
              className="progress-bar-fill"
              style={{ width: `${(progress / duration) * 100}%` }}
            />
          </div>
          <div className="player-time">
            {formatTime(progress)} / {formatTime(duration)}
          </div>
        </div>
      </div>
    </div>
  );
};

const SongItem = ({ song, currentlyPlaying, isPlaying, onPlay }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className={`result-item ${currentlyPlaying?.id === song.id ? 'playing' : ''} ${isMobile ? 'mobile' : ''}`}>
      <div className="result-artwork">
        <img src={song.artwork_url} alt={song.title} className="result-image" />
        <button
          className={`play-button ${currentlyPlaying?.id === song.id && isPlaying ? 'playing' : ''}`}
          onClick={() => onPlay(song)}
          disabled={!song.preview_url}
        >
          {currentlyPlaying?.id === song.id && isPlaying ? (
            <Pause className="play-icon" />
          ) : (
            <Play className="play-icon" />
          )}
        </button>
      </div>
      
      <div className="result-info">
        <div className="result-title">{song.title}</div>
        <div className="result-artist">{song.artist}</div>
        <div className="result-album">{song.album} • {song.year}</div>
      </div>
    </div>
  );
};

const CreateCrateModal = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BACKEND}/crates/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      });

      if (!response.ok) throw new Error('Failed to create crate');

      const data = await response.json();
      onSubmit(data);
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-modal-overlay" onClick={onClose}>
      <div className="auth-modal" onClick={e => e.stopPropagation()}>
        <h2 className="auth-modal-title">Create New Crate</h2>
        {error && <div className="auth-error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="auth-form">
          <input
            type="text"
            placeholder="Crate Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="auth-input"
            required
          />
          <button type="submit" className="auth-submit" disabled={loading}>
            {loading ? 'Creating...' : 'Create Crate'}
          </button>
        </form>
      </div>
    </div>
  );
};

const CrateView = ({ crate, onBack, currentlyPlaying, isPlaying, onPlay, progress, duration, onSeek, onPlayPause }) => {
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayLimit, setDisplayLimit] = useState(ITEMS_PER_PAGE);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${BACKEND}/crates/${crate.id}/tracks`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch tracks');
        const data = await response.json();
        setTracks(data.map(track => track.song));
      } catch (err) {
        console.error('Error fetching tracks:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTracks();
  }, [crate.id]);

  const handleShowMore = () => {
    setDisplayLimit(prev => Math.min(prev + ITEMS_PER_PAGE, tracks.length));
  };

  return (
    <div className={`crate-results-page ${isMobile ? 'mobile' : ''}`}>
      <div className={`back-button ${isMobile ? 'mobile' : ''}`} onClick={onBack}>
        <ArrowLeft className="back-icon" />
        {!isMobile && "Back to Crates"}
      </div>

      <div className="crate-results-section">
        <div className="crate-results-header">
          <div>{crate.name} ({tracks.length} tracks)</div>
        </div>

        {loading ? (
          <div className="loading-container">
            <Loader className="loading-icon animate-spin" />
          </div>
        ) : (
          <>
            <div className={`crate-results-list ${isMobile ? 'mobile' : ''}`}>
              {tracks.slice(0, displayLimit).map(song => (
                <SongItem
                  key={song.id}
                  song={song}
                  currentlyPlaying={currentlyPlaying}
                  isPlaying={isPlaying}
                  onPlay={onPlay}
                />
              ))}
            </div>

            {displayLimit < tracks.length && (
              <button
                className={`show-more-button ${isMobile ? 'mobile' : ''}`}
                onClick={handleShowMore}
              >
                <ChevronDown className="show-more-icon" />
                Show More ({tracks.length - displayLimit} remaining)
              </button>
            )}
          </>
        )}
      </div>

      {currentlyPlaying && (
        <FloatingPlayer
          currentSong={currentlyPlaying}
          isPlaying={isPlaying}
          progress={progress}
          duration={duration}
          onSeek={onSeek}
          onPlayPause={onPlayPause}
        />
      )}
    </div>
  );
};

const CratesPage = ({ user, onLogin }) => {
  const [crates, setCrates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedCrate, setSelectedCrate] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(new Audio());

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const fetchCrates = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setLoading(false);
          return;
        }

        const response = await fetch(`${BACKEND}/crates/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch crates');
        const data = await response.json();
        setCrates(data);
      } catch (err) {
        console.error('Error fetching crates:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCrates();

    return () => {
      const audio = audioRef.current;
      if (audio) {
        audio.pause();
        audio.src = '';
      }
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setProgress(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handlePlay = async (song) => {
    const audio = audioRef.current;

    if (currentlyPlaying?.id === song.id) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        await audio.play();
        setIsPlaying(true);
      }
      return;
    }

    try {
      audio.src = song.preview_url;
      setCurrentlyPlaying(song);
      await audio.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Playback failed:', error);
      setCurrentlyPlaying(null);
      setIsPlaying(false);
    }
  };

  const handleSeek = (time) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = time;
      setProgress(time);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Loader className="loading-icon animate-spin" />
      </div>
    );
  }

  // Add authentication check
  if (!user) {
    return (
      <div className="profile-page">
        <div className="auth-prompt">
          <div className="auth-prompt-title">
            <AlertTriangle size={24} />
            You aren't logged in
          </div>
          <p className="auth-prompt-text">
            Please log in to access your crates, save tracks, and manage your music collection.
          </p>
          <button className="profile-button" onClick={onLogin}>
            Login to Continue
          </button>
        </div>
      </div>
    );
  }

  if (selectedCrate) {
    return (
      <CrateView
        crate={selectedCrate}
        onBack={() => setSelectedCrate(null)}
        currentlyPlaying={currentlyPlaying}
        isPlaying={isPlaying}
        onPlay={handlePlay}
        progress={progress}
        duration={duration}
        onSeek={handleSeek}
        onPlayPause={() => handlePlay(currentlyPlaying)}
      />
    );
  }

  return (
    <div className={`crate-results-page ${isMobile ? 'mobile' : ''}`}>
      <div className="crate-results-section">
        <div className="crate-results-header">
          <div>Your Crates ({crates.length})</div>
          <button
            className="create-crate-button"
            onClick={() => setShowCreateModal(true)}
          >
            <FolderPlus size={20} />
            <span>New Crate</span>
          </button>
        </div>

        <div className="crates-grid">
          {crates.map(crate => (
            <div
              key={crate.id}
              className="crate-card"
              onClick={() => setSelectedCrate(crate)}
            >
              <div className="crate-info">
                <h3>{crate.name}</h3>
                <p>{crate.track_count} tracks</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {currentlyPlaying && (
        <FloatingPlayer
          currentSong={currentlyPlaying}
          isPlaying={isPlaying}
          progress={progress}
          duration={duration}
          onSeek={handleSeek}
          onPlayPause={() => handlePlay(currentlyPlaying)}
        />
      )}

      <CreateCrateModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={(crate) => {
          setCrates([...crates, crate]);
          setShowCreateModal(false);
        }}
      />
    </div>
  );
};

export default CratesPage;
