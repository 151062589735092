import React, { useState } from 'react';
import { User, Crown, CreditCard, AlertTriangle } from 'lucide-react';
import './ProfilePage.css';

const BACKEND = "https://emergesound.ai/api";

const ProfilePage = ({ user, onLogin }) => {
  const [isUpgrading, setIsUpgrading] = useState(false);

  const handleUpgradeClick = async () => {
    setIsUpgrading(true);
    try {
      const response = await fetch(`${BACKEND}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          price_id: 'price_xxx', // Your actual Stripe price ID
          success_url: `${window.location.origin}/profile?success=true`,
          cancel_url: `${window.location.origin}/profile?success=false`
        })
      });

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsUpgrading(false);
    }
  };

  if (!user) {
    return (
      <div className="profile-page">
        <div className="auth-prompt">
          <div className="auth-prompt-title">
            <AlertTriangle size={24} />
            You arent logged in
          </div>
          <p className="auth-prompt-text">
            Please log in to access your profile, save tracks to crates, and unlock premium features.
          </p>
          <button className="profile-button" onClick={onLogin}>
            Login to Continue
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="profile-container">
        {/* Profile Header */}
        <div className="profile-header">
          <div className="profile-avatar">
            <User size={32} />
          </div>
          <div className="profile-info">
            <h1 className="profile-title">Welcome back</h1>
            <p className="profile-email">{user.email}</p>
          </div>
        </div>

        {/* Account Stats */}
        <div className="profile-stats">
          <h2 className="premium-title">
            <Crown size={20} />
            Account Status
          </h2>
          <div className="stat-item">
            <span className="stat-label">Plan:</span>
            <span className="stat-value">
              {user.account_type === 'premium' ? 'Premium' : 'Free Tier'}
            </span>
          </div>
          {/*<div className="stat-item">
              <span className="stat-label">Credits:</span>
              <span className="stat-value">
              {user.credits_remaining} / {user.max_credits}
              </span>
            </div>*/}
        </div>

        {/* Premium Upgrade Section */}
        {user.account_type !== 'premium' && (
          <div className="premium-upgrade">
            <h3 className="premium-title">
              <CreditCard size={20} />
              Upgrade to Premium
            </h3>
            <ul className="premium-features">
              <li className="premium-feature">
                <span>•</span>
                Early access to beta features
              </li>
              <li className="premium-feature">
                <span>•</span>
                Extra utility features
              </li>
              {/*<li className="premium-feature">
                <span>•</span>
                50 daily credits instead of 10
              </li>
              <li className="premium-feature">
                <span>•</span>
                Unlimited crates for saving tracks
              </li>
              <li className="premium-feature">
                <span>•</span>
                Priority support
              </li>*/}
            </ul>
            {/*<button
                onClick={handleUpgradeClick}
                disabled={isUpgrading}
                className="profile-button"
                >*/}
              <button
                  onClick={handleUpgradeClick}
                  disabled={true}
                  className="profile-button"
               >
              {/*isUpgrading ? 'Processing...' : 'Upgrade Now'*/}
              {'Coming Soon'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
