import React, { useState, useEffect, useRef } from 'react';
import ProfilePage from './ProfilePage';
import CassettePage from './CassettePage';
import ResultsPage from './ResultsPage';
import CratesPage from './CratesPage';
import { AccountStatus, AuthModal } from './UserComponents';
import { User, Search, Package2, Instagram } from 'lucide-react';
import './App.css';

const BACKEND = "https://emergesound.ai/api";

// Custom Discord icon component
const Discord = ({ size = 24, color = "currentColor", className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    className={`lucide-icon ${className}`}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/>
  </svg>
);

// Custom TikTok icon component
const TikTok = ({ size = 24, color = "currentColor", className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    className={`lucide-icon ${className}`}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"/>
  </svg>
);

const NavBar = ({ currentPage, onNavigate }) => {
  const navItems = [
    { id: 'profile', icon: User, label: 'Profile' },
    { id: 'cassette', icon: Search, label: 'Search' },
    { id: 'crates', icon: Package2, label: 'Saved Crates' }
  ];

  const socialLinks = [
    { id: 'discord', icon: Discord, url: 'https://discord.gg/7xn7agbY' },
    { id: 'tiktok', icon: TikTok, url: 'https://www.tiktok.com/@emergesound.ai' },
    { id: 'instagram', icon: Instagram, url: 'https://www.instagram.com/emergesound.ai/' }
  ];

  return (
    <nav className="nav-bar">
      <div className="nav-bar-container">
        <div className="nav-buttons">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <button
                key={item.id}
                onClick={() => onNavigate(item.id)}
                className={`nav-button ${currentPage === item.id ? 'active' : ''}`}
              >
                <Icon size={20} />
                <span className="nav-button-label">{item.label}</span>
              </button>
            );
          })}
        </div>

        <div className="social-links">
          {socialLinks.map((link) => {
            const Icon = link.icon;
            return (
              <a
                key={link.id}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <Icon size={20} />
              </a>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

const VideoBackground = ({ isPlaying, onVideoEnd, style = {} }) => {
  const videoRef = useRef(null);
  const PLAYBACK_SPEED = 0.3;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.playbackRate = PLAYBACK_SPEED;
      
      if (isPlaying) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().catch(err => console.error('Video autoplay failed:', err));
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <video
      ref={videoRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 100,
        ...style
      }}
      playsInline
      muted
      onEnded={onVideoEnd}
    >
      <source src="/background_video.mp4" type="video/mp4" />
    </video>
  );
};

function App() {
  const [currentPage, setCurrentPage] = useState('cassette');
  const [initialData, setInitialData] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  
  // Audio state
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio());
  
  // Auth state
  const [user, setUser] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [session, setSession] = useState(null);
  const [credits, setCredits] = useState(5);

  // Add preloading effect
  useEffect(() => {
    // Preload video when page loads
    const preloadVideo = () => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = '/background_video.mp4';
      link.as = 'video';
      link.type = 'video/mp4';
      document.head.appendChild(link);
    };

    preloadVideo();
  }, []);

  // Audio cleanup effect
  useEffect(() => {
    const audio = audioRef.current;
    return () => {
      audio.pause();
      audio.src = '';
    };
  }, []);
    
  const handlePlay = async (song) => {
    const audio = audioRef.current;

    if (currentlyPlaying?.id === song.id) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        await audio.play();
        setIsPlaying(true);
      }
      return;
    }

    try {
      audio.src = song.preview_url;
      setCurrentlyPlaying(song);
      await audio.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Playback failed:', error);
      setCurrentlyPlaying(null);
      setIsPlaying(false);
    }
  };
    
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return fetchAnonymousCredits();
    }

    try {
      const response = await fetch(`${BACKEND}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      setUser(userData);
      setCredits(userData.credits_remaining);
      return userData;
    } catch (error) {
      console.error('Error fetching user:', error);
      localStorage.removeItem('token');
      return fetchAnonymousCredits();
    }
  };
    
  useEffect(() => {
    fetchUserData();
    
    // Check credits every minute
    const interval = setInterval(fetchUserData, 60000);
    return () => clearInterval(interval);
  }, []);

  const consumeCredit = async () => {
    const token = localStorage.getItem('token');
    const fingerprint = localStorage.getItem('deviceFingerprint');
  
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
  
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const body = token ? {} : { fingerprint };
  
      const response = await fetch(`${BACKEND}/auth/credits/consume`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });
  
      if (!response.ok) {
        if (response.status === 402) {
          setShowAuthModal(true);
          return false;
        }
        const errorData = await response.json();
        console.error('Credit consumption failed:', errorData.detail);
        return false;
      }
  
      const data = await response.json();
      setCredits(data.credits_remaining);
      return true;
    } catch (error) {
      console.error('Error consuming credit:', error);
      return false;
    }
  };
  
  const fetchAnonymousCredits = async () => {
    try {
      const fingerprint = localStorage.getItem('deviceFingerprint') ||
        (() => {
          const newFingerprint = crypto.randomUUID();
          localStorage.setItem('deviceFingerprint', newFingerprint);
          return newFingerprint;
        })();
  
      const response = await fetch(`${BACKEND}/auth/credits?fingerprint=${fingerprint}`);
      if (!response.ok) {
        throw new Error('Failed to fetch credits');
      }
  
      const data = await response.json();
      setCredits(data.credits_remaining);
      return data;
    } catch (error) {
      console.error('Error fetching credits:', error);
      setCredits(0);
      return null;
    }
  };

  const handleSubmit = async (data) => {
    //if (await consumeCredit()) {
      setIsError(false);
      setInitialData(data);
      setIsTransitioning(true);
      setIsVideoPlaying(true);
      setTimeout(() => {
        setCurrentPage('results');
        setIsTransitioning(false);
      }, 500);
    //}
  };
    
  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  const handleBack = () => {
    setIsTransitioning(true);
    setInitialData(null);
    setIsError(false);
    setIsTransitioning(false);
    setTimeout(() => {
      setCurrentPage('cassette');
    }, 500);
  };

  // Handle background image loading
  useEffect(() => {
    const img = new Image();
    const timeoutId = setTimeout(() => {
      img.src = currentPage === 'cassette' ? '/background.jpg' : '/background2.jpg';
      img.onload = () => setBackgroundImage(img.src);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [currentPage]);
    
    const handleCreateNewCrate = () => {
        setCurrentPage('crates');
      };
    
  return (
    <div
      className={`App ${isTransitioning ? 'transitioning' : ''}`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Video Background */}
      {isVideoPlaying && (
        <VideoBackground
          isPlaying={isVideoPlaying}
          onVideoEnd={handleVideoEnd}
          style={{
            objectPosition: currentPage === 'cassette' ? 'center' : 'center'
          }}
        />
      )}

      {/* Auth Components */}
      <AccountStatus
        user={session?.user}
        credits={credits}
        onLogin={() => setShowAuthModal(true)}
        onLogout={async () => {
          setSession(null)
          setCredits(5)
        }}
      />
      
      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    
      {/* Page Components */}
      {currentPage === 'cassette' && (
        <CassettePage
          onSubmit={handleSubmit}
          isTransitioning={isTransitioning}
          isError={isError}
          setIsError={setIsError}
          consumeCredit={consumeCredit}
          credits={credits}
        />
      )}
      
      {currentPage === 'results' && (
        <ResultsPage
          initialData={initialData}
          onBack={handleBack}
          consumeCredit={consumeCredit}
          session={session}
          setIsVideoPlaying={setIsVideoPlaying}
          onCreateNewCrate={handleCreateNewCrate}
          setShowAuthModal={setShowAuthModal}
        />
      )}
          
      {currentPage === 'profile' && (
        <ProfilePage
          user={user}
          onLogin={() => setShowAuthModal(true)}
        />
      )}
          
      {currentPage === 'crates' && (
        <CratesPage
        user={user}
        onLogin={() => setShowAuthModal(true)}
        currentlyPlaying={currentlyPlaying}
        isPlaying={isPlaying}
        onPlay={handlePlay}
        />
      )}
          
      <NavBar
        currentPage={currentPage}
        onNavigate={(page) => {
          setCurrentPage(page);
        }}
      />
      
      <div className="vhs-overlay"></div>
      <div className="noise"></div>
    </div>
  );
}

export default App;
